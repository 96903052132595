'use client';

import { useTranslations } from 'next-intl';
import { Share2, Users, MessageSquare, ArrowRight, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { WHATSAPP_COMMUNITY_URL } from '@/constants/whatsapp';
export default function WhatsappCommunity() {
  const t = useTranslations('home_page.whatsapp_community');
  const {
    toast
  } = useToast();
  const features = [{
    icon: Users,
    title: t('features.find_partners.title'),
    description: t('features.find_partners.description')
  }, {
    icon: MessageSquare,
    title: t('features.join_discussions.title'),
    description: t('features.join_discussions.description')
  }, {
    icon: Share2,
    title: t('features.share_experience.title'),
    description: t('features.share_experience.description')
  }];
  return <section className="bg-gradient-to-b from-white to-blue-50 py-16 overflow-hidden" data-sentry-component="WhatsappCommunity" data-sentry-source-file="WhatsappCommunity.tsx">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left content */}
          <div className="w-full lg:w-1/2 space-y-8">
            <div className="space-y-4">
              <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="WhatsappCommunity.tsx">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525L2 22L7.5475 20.9565C8.88837 21.6244 10.4003 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="WhatsappCommunity.tsx" />
                </svg>
                {t('community_badge')}
              </div>
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">{t('heading')}</h2>
              <p className="text-lg text-gray-600 max-w-xl">{t('description')}</p>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {features.map((feature, index) => <div key={index} className="flex flex-col p-5 rounded-xl bg-white shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
                  <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center mb-4">
                    <feature.icon className="w-5 h-5 text-primary" />
                  </div>
                  <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600 text-sm">{feature.description}</p>
                </div>)}
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <Button size="lg" className="bg-[#25D366] hover:bg-[#128C7E] text-white font-medium" onClick={() => window.open(WHATSAPP_COMMUNITY_URL, '_blank')} data-sentry-element="Button" data-sentry-source-file="WhatsappCommunity.tsx">
                {t('join_button')}
                <ArrowRight className="ml-2 h-4 w-4" data-sentry-element="ArrowRight" data-sentry-source-file="WhatsappCommunity.tsx" />
              </Button>
              <Button variant="outline" size="lg" className="text-gray-700 border-gray-300" onClick={() => {
              navigator.clipboard.writeText(WHATSAPP_COMMUNITY_URL);
              toast({
                title: t('copy_success_title'),
                description: t('copy_success_description'),
                variant: 'success',
                duration: 3000
              });
            }} data-sentry-element="Button" data-sentry-source-file="WhatsappCommunity.tsx">
                {t('share_button')}
              </Button>
            </div>
          </div>

          {/* Right illustration/mockup */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end relative">
            <div className="relative w-full max-w-md">
              {/* Phone mockup with whatsapp chat */}
              <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                {/* Dynamic Island / Notch */}
                <div className="absolute top-0 left-1/2 -translate-x-1/2 h-[30px] w-[120px] rounded-b-[1rem] z-10 flex items-end justify-center">
                  <div className="h-[25px] w-[80px] bg-black rounded-[24px] absolute -bottom-[4px]">
                    {/* Camera dot */}
                    <div className="absolute right-[20px] top-1/2 -translate-y-1/2 w-[6px] h-[6px] rounded-full bg-[#0f0f0f] ring-1 ring-opacity-10 ring-white"></div>
                  </div>
                </div>

                {/* Side buttons */}
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>

                <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-[#ECE5DD]">
                  {/* Status bar */}
                  <div className="h-[40px] w-full bg-[#128C7E] flex justify-between items-center px-6 pt-2 relative z-0">
                    <div className="text-white text-xs font-medium">9:41</div>
                    <div className="flex items-center space-x-1">
                      <svg className="h-3 w-3 text-white" fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="WhatsappCommunity.tsx">
                        <path d="M12 22c5.514 0 10-4.486 10-10S17.514 2 12 2 2 6.486 2 12s4.486 10 10 10zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8z" data-sentry-element="path" data-sentry-source-file="WhatsappCommunity.tsx" />
                      </svg>
                      <svg className="h-3 w-3 text-white" fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="WhatsappCommunity.tsx">
                        <path d="M12 22c5.514 0 10-4.486 10-10S17.514 2 12 2 2 6.486 2 12s4.486 10 10 10zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8z" data-sentry-element="path" data-sentry-source-file="WhatsappCommunity.tsx" />
                      </svg>
                      <svg className="h-3 w-4 text-white" fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="WhatsappCommunity.tsx">
                        <path d="M17 5.5v13a1.5 1.5 0 01-3 0v-13a1.5 1.5 0 013 0zM12 5.5v13a1.5 1.5 0 01-3 0v-13a1.5 1.5 0 013 0zM7 5.5v13a1.5 1.5 0 01-3 0v-13a1.5 1.5 0 013 0z" data-sentry-element="path" data-sentry-source-file="WhatsappCommunity.tsx" />
                      </svg>
                    </div>
                  </div>

                  {/* WhatsApp header */}
                  <div className="w-full bg-[#128C7E] h-10 flex items-center px-4">
                    <div className="text-white font-medium w-full text-center">{t('group_name')}</div>
                  </div>

                  {/* Chat mockup */}
                  <div className="bg-[#ECE5DD] p-3 h-[478px] overflow-hidden flex flex-col">
                    <div className="bg-white rounded-lg p-2 mb-2 self-start max-w-[80%] shadow-sm">
                      <p className="text-sm">{t('chat_message_1')}</p>
                      <p className="text-[10px] text-gray-500 text-right">10:30 AM</p>
                    </div>

                    <div className="bg-[#DCF8C6] rounded-lg p-2 mb-2 self-end max-w-[80%] shadow-sm">
                      <p className="text-sm">{t('chat_message_2')}</p>
                      <p className="text-[10px] text-gray-500 text-right">10:31 AM</p>
                    </div>

                    <div className="bg-white rounded-lg p-2 mb-2 self-start max-w-[80%] shadow-sm">
                      <p className="text-sm">{t('chat_message_3')}</p>
                      <p className="text-[10px] text-gray-500 text-right">10:32 AM</p>
                    </div>

                    <div className="bg-white rounded-lg p-2 mb-2 self-start max-w-[80%] shadow-sm">
                      <p className="text-sm">{t('chat_message_4')}</p>
                      <p className="text-[10px] text-gray-500 text-right">10:33 AM</p>
                    </div>

                    <div className="mt-auto mx-auto bg-white rounded-full px-4 py-2 flex items-center gap-2 shadow-md cursor-pointer hover:bg-gray-50 transition-colors" onClick={() => window.open(WHATSAPP_COMMUNITY_URL, '_blank')} role="button" aria-label={t('join_button')}>
                      <p className="text-sm font-medium text-[#128C7E]">{t('join_now')}</p>
                      <ArrowRight className="h-4 w-4 text-[#128C7E]" data-sentry-element="ArrowRight" data-sentry-source-file="WhatsappCommunity.tsx" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Decorative elements */}
              <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-primary/5 rounded-full z-[-1]"></div>
              <div className="absolute -top-12 -right-12 w-64 h-64 bg-primary/5 rounded-full z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}