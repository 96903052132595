'use client';

import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useTranslations } from 'next-intl';
import * as Sentry from '@sentry/nextjs';
export default function SalesForm() {
  const t = useTranslations('home_page.sales');
  const {
    toast
  } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendErrorToast = () => {
    toast({
      title: t('toast.error.title'),
      description: t('toast.error.description'),
      variant: 'destructive'
    });
  };
  const sendSuccessToast = () => {
    toast({
      title: t('toast.success.title'),
      description: t('toast.success.description'),
      variant: 'success'
    });
  };
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(event.currentTarget);
    const body = Object.fromEntries(formData.entries());
    try {
      const response = await fetch('/api/emails/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        sendSuccessToast();
        (event.target as HTMLFormElement).reset();
      } else {
        sendErrorToast();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          body
        },
        tags: {
          context: 'SalesForm',
          step: 'handleFormSubmit'
        }
      });
      sendErrorToast();
    } finally {
      setIsSubmitting(false);
    }
  };
  return <section id="sales-form" className="w-full container mx-auto py-12 md:py-16 lg:py-20" data-sentry-component="SalesForm" data-sentry-source-file="SalesForm.tsx">
      <div>
        <div className="text-center space-y-4 mb-8">
          <h2 className="text-3xl md:text-4xl font-semibold tracking-tighter px-4">{t('heading')}</h2>
          <p className="text-muted-foreground text-lg md:text-xl">{t('subheading')}</p>
        </div>
        <Card data-sentry-element="Card" data-sentry-source-file="SalesForm.tsx">
          <form onSubmit={handleFormSubmit}>
            <CardContent className="space-y-6 pt-6" data-sentry-element="CardContent" data-sentry-source-file="SalesForm.tsx">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="name" data-sentry-element="Label" data-sentry-source-file="SalesForm.tsx">{t('form.name')}</Label>
                  <Input required id="name" name="name" placeholder={t('form.name_placeholder')} data-sentry-element="Input" data-sentry-source-file="SalesForm.tsx" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="club-name" data-sentry-element="Label" data-sentry-source-file="SalesForm.tsx">{t('form.club_name')}</Label>
                  <Input required id="club_name" name="club_name" placeholder={t('form.club_placeholder')} data-sentry-element="Input" data-sentry-source-file="SalesForm.tsx" />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="email" data-sentry-element="Label" data-sentry-source-file="SalesForm.tsx">{t('form.email')}</Label>
                  <Input required id="email" name="email" type="email" placeholder={t('form.email_placeholder')} data-sentry-element="Input" data-sentry-source-file="SalesForm.tsx" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="phone" data-sentry-element="Label" data-sentry-source-file="SalesForm.tsx">{t('form.phone')}</Label>
                  <Input required id="phone" name="phone" type="tel" placeholder={t('form.phone_placeholder')} data-sentry-element="Input" data-sentry-source-file="SalesForm.tsx" />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address" data-sentry-element="Label" data-sentry-source-file="SalesForm.tsx">{t('form.questions')}</Label>
                <Textarea required={false} id="questions" name="questions" rows={3} placeholder={t('form.questions_placeholder')} data-sentry-element="Textarea" data-sentry-source-file="SalesForm.tsx" />
              </div>
            </CardContent>
            <CardFooter className="flex justify-end" data-sentry-element="CardFooter" data-sentry-source-file="SalesForm.tsx">
              <Button type="submit" disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="SalesForm.tsx">
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {t('form.submit')}
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </section>;
}
function SuccessIcon() {
  return <div className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-green-500" data-sentry-component="SuccessIcon" data-sentry-source-file="SalesForm.tsx">
      <CheckIcon className="w-4 h-4 text-white" data-sentry-element="CheckIcon" data-sentry-source-file="SalesForm.tsx" />
    </div>;
}
function CheckIcon(props: any) {
  return <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="svg" data-sentry-component="CheckIcon" data-sentry-source-file="SalesForm.tsx">
      <path d="M20 6 9 17l-5-5" data-sentry-element="path" data-sentry-source-file="SalesForm.tsx" />
    </svg>;
}
function ErrorIcon() {
  return <div className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-red-500" data-sentry-component="ErrorIcon" data-sentry-source-file="SalesForm.tsx">
      <XIcon className="text-white w-4 h-4" data-sentry-element="XIcon" data-sentry-source-file="SalesForm.tsx" />
    </div>;
}
function XIcon(props: any) {
  return <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="svg" data-sentry-component="XIcon" data-sentry-source-file="SalesForm.tsx">
      <path d="M18 6 6 18" data-sentry-element="path" data-sentry-source-file="SalesForm.tsx" />
      <path d="m6 6 12 12" data-sentry-element="path" data-sentry-source-file="SalesForm.tsx" />
    </svg>;
}