'use client';

import { Card, Carousel } from '../ui/apple-cards-carousel';
import { useTranslations } from 'next-intl';
export default function BlogCarousel() {
  const t = useTranslations('home_page.blog_carousel');
  const blogPosts = [{
    title: t('articles.padel.title'),
    category: t('category'),
    src: '/images/blog/padel.jpg',
    href: `/blog/${t('articles.padel.slug')}`
  }, {
    title: t('articles.tennis.title'),
    category: t('category'),
    src: '/images/blog/tennis.jpg',
    href: `/blog/${t('articles.tennis.slug')}`
  }, {
    title: t('articles.squash.title'),
    category: t('category'),
    src: '/images/blog/squash.jpg',
    href: `/blog/${t('articles.squash.slug')}`
  }, {
    title: t('articles.badminton.title'),
    category: t('category'),
    src: '/images/blog/badminton.jpg',
    href: `/blog/${t('articles.badminton.slug')}`
  }, {
    title: t('articles.table_tennis.title'),
    category: t('category'),
    src: '/images/blog/table-tennis.jpg',
    href: `/blog/${t('articles.table_tennis.slug')}`
  }];
  return <section className="py-8 bg-white dark:bg-neutral-950" data-sentry-component="BlogCarousel" data-sentry-source-file="BlogCarousel.tsx">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white">{t('title')}</h2>
        <Carousel items={blogPosts.map((post, index) => <Card key={post.title} card={post} index={index} layout={true} />)} data-sentry-element="Carousel" data-sentry-source-file="BlogCarousel.tsx" />
      </div>
    </section>;
}